import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { PageContainer } from "components/layout";
import { getImage } from "utils/image";

export default function NotFound() {
  const theme = useTheme();

  return (
    <PageContainer>
      {/* <Helmet
        title={`404 Page Not Found`}
        titleTemplate="%s | Alfin's Blog"
      /> */}
      <Helmet title={`Blog`} titleTemplate="%s | Alfin's Blog" />

      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <img
            placeholder="blurred"
            src={getImage(
              theme.palette.mode == "dark"
                ? `/assets/images/404-dark.png`
                : `/assets/images/404.png`
            )}
            alt="no results"
            style={{
              width: 300,
              display: "block",
              margin: "0px auto",
            }}
          />

          <Typography
            variant="h5"
            color="primary"
            sx={{
              mt: 2,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Page Not Found
          </Typography>

          <Typography
            variant="h6"
            color="text.disabled"
            sx={{
              textAlign: "center",
            }}
          >
            Oops.. The page you are looking for has been removed or relocated!
          </Typography>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
